<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card class="justify-center">
        <div class="vx-card__title mb-6">
            <h2 class="text-center">Thank you.</h2>
          <h4 class="text-center">Your email address has been verified successfully.</h4>
          <h4 class="text-center">[ Please close browser.]</h4>
<!--          <div class="flex justify-center">-->
<!--          <vs-button-->
<!--            class="justify-center mt-3"-->
<!--            @click="close_window()"-->
<!--          >Close Window</vs-button>-->
<!--          </div>-->
        </div>

<!--          <a-->
<!--            href="javascript:close_window();"-->
<!--            class="w-full sm:w-auto"-->

<!--          >Close Window</a>-->
      </vx-card>
    </div>
  </div>
</template>


<script>
  export default {
    data() {
      return {
        value1: '',
        myWindow:'',
      }
    }
  }

</script>

